import { Table, TableContainer, TableRow, TableBody, TableCell, TableHead } from "@mui/material";
import { parseMDate, formatDateTime } from "../types";

export default function PendingList({ pendingBookings } : { pendingBookings:Array<any> }) {

    function processBooking(booking : any, index : number): React.ReactNode {
        return (
            <TableRow key={index} className="bg-slate-100 rounded-md mt-2 mb-2 p-1">
                <TableCell>{booking.first_name} {booking.last_name}</TableCell>
                {/* Tokenize datetime string by whitespace, extract date elements, map to space-separated string */}
                <TableCell>{formatDateTime(parseMDate(booking.appt_date))}</TableCell>
                <TableCell>{parseMDate(booking.appt_date).toLocaleTimeString()}</TableCell>
            </TableRow>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {pendingBookings.slice(0, 3).map((booking, index) => processBooking(booking, index))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
