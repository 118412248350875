/*
	Handles user authentication and data fetching
*/

import React, { createContext, useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContextType, CampaignApiCache, UserData} from "./types";
import { redirect } from "react-router-dom";


/* Type definitions */


const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: { children: JSX.Element }) {

	const [authed, setAuthed] = useState(false); // Login state
	const [fetchingAuth, setFetchingAuth] = useState(true);
	const [googleAuthed, setGoogleAuthed] = useState(false); // Google OAuth state
	const [userData, setUserData] = useState<UserData | null>(null); 

	/* Pulls profile data and cached campaign data */
	async function fetchUserData() {
		const res = await fetch("/get-user-data");

		if(res.status !== 200) {

			// Unsuccessful data fetch
            //if(res.status === 401) {
            //    window.location.href = "/login";
            //}

			return;
		}
		
		try {
			const data: UserData = await res.json();

			setUserData(data);
			if(data.google_authed) {
				setGoogleAuthed(true);
			}
		} catch {
			// Couldn't parse JSON data
			alert("Couldn't fetch user data!");
		}
	}

	
	/* Forces the server to request new campaign data and re-generate AI analysis */
	async function forceUpdateCampaignCache() {
		const res = await fetch("/get-campaign-data");
		if(res.status !== 200) {
			// Unsuccessful data fetch
			return;
		}
		const data: CampaignApiCache = await res.json();
		
		// Update api_cache field
		if(userData) {
			setUserData({
				...userData, 
				account_data: { 
					...userData.account_data,
					api_cache: data
				}
			});
		}
	}

	async function validateToken() {
		const res = await fetch("/validate-token");

		if(res.status !== 200) {
            console.log("Couldn't validate token.");

            // Redirect to login window if not already there
            if (!window.location.href.includes("/login") && window.location.pathname !== "/") {
                window.location.href = "/login";
                return;
            }
        }

		setAuthed(true);
		setFetchingAuth(false);
		fetchUserData();
	}

	/* Internal OAuth function definition */
	const googleAuth = useGoogleLogin({
		onSuccess: async (credentialResponse) => {
			// Display success message to user
		},
		onError: () => {
			// Display error message to user
		},
		redirect_uri: 'https://startbase.site/auth',
		//redirect_uri: 'http://127.0.0.1:8000/auth',
		ux_mode: 'redirect',
		flow: 'auth-code',
	});

	
	const removeAuth = async () => {
		const response = await fetch('/unauth');

		if(response.status === 200) {
            // 'See other' redirect indicates successful authorization
			setGoogleAuthed(false);
		} else {
			// Couldn't disconnect Google Account
		}
	};


	/* Handles setting authentication status */
	function login() {
		setAuthed(true);
	}
	function authGoogle() {
		//googleAuth();
    
		//setGoogleAuthed(true);
        window.location.href = "/auth";
	}
	function logout() {
		setAuthed(false);
	}
	function unauthGoogle() {
		removeAuth();
	}


	/* Fetch user data on first mount */
	useEffect(() => {
		validateToken();
	}, []);
	

	return (
		<AuthContext.Provider value={{authed, fetchingAuth, login, logout, 
									googleAuthed, authGoogle, unauthGoogle, 
									userData, fetchUserData, 
									forceUpdateCampaignCache}}>
			{children}
		</AuthContext.Provider>
	);

}


export { AuthProvider, AuthContext };
