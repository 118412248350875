import { useState, useEffect, useContext } from "react";
import Sidebar from "../ui/sidebar";
import AiConfig from "./ai-config";
import TopNav from "../ui/top-nav";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { AuthContext } from "../auth";


export default function AiPage() {

	const authInfo = useContext(AuthContext);

	if(!authInfo) throw new Error("Invalid auth context");
	const userData = authInfo.userData;
	
	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);


    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />

            <TopNav/>

            <main className="md:ml-64 p-8 pt-28" >
                
				<h1 className="text-2xl mb-8 text-heading">AI Analysis</h1>

                <div className="bg-white rounded-md md:p-4">

					<div className="md:flex md:flex-row">
						<AiConfig 
							aiSupplement={userData?.account_data.ai_supplement || {'summary': '', 'demographics': '', 'ad_goals': ''}}
						/>

						<div className="w-full md:w-2/3 bg-[#F4F9FF] rounded-lg p-2 md:p-8 pt-12 pb-12">
							{/* AI response box */}
							<TextField label="Generated Analysis" InputLabelProps={{ shrink: true }} value={
							authInfo.googleAuthed ?
								userData?.account_data.api_cache.ai
							:
								'Connect your Google Account to Access AI Campaign Analysis.'

							} className="w-full" multiline minRows={25}></TextField>
							
						</div>
					</div>

				</div>

            </main>
        </div>
    
    );
}
