import { useState, useContext, useEffect } from "react";
import Sidebar from "../ui/sidebar";
import TopNav from "../ui/top-nav";
import { Card, CardContent, Alert, CircularProgress, TextField, Button } from '@mui/material';
import { AuthContext } from "../auth";



export default function SettingsPage() {
	
	const authInfo = useContext(AuthContext);
	if(!authInfo) throw new Error("Invalid auth state.");
	const userData = authInfo.userData;

	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);

    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />

        	<TopNav />

            <main className="md:ml-64 p-8 pt-28">
				<h1 className="text-2xl mb-8 text-heading">Startbase Account Settings</h1>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

					{/* Allow users to add/update their Google Ads Customer ID */}
					<Card>
						<CardContent>
							<h1 className="text-heading mb-4">Update Customer ID</h1>
							<form action="/add-customer-id" method="post" className="mt-4">

								<TextField name="customer-id" value={userData?.account_data.customer_id || ''} label="Customer ID" fullWidth required/>

								<h2 className="text-main mb-4 mt-6">Optional Manager ID</h2>
								<TextField name="manager-id" value={userData?.account_data.manager_id || ''} label="Manager ID" fullWidth/>

								<div className="h-4"></div>
								<div className="flex justify-end">
									<Button type="submit" variant="contained">Update</Button>
								</div>

							</form>
						</CardContent>
					</Card>

					<Card>
						<CardContent>
							<h1 className="text-heading mb-4">Update Password</h1>

							<form className="" action="/change-password" method="post">
								<TextField name="new-password" label="New Password" fullWidth required/>
								<div className="h-4"></div>

								<div className="flex justify-end">
									<Button type="submit" variant="contained">Update</Button>
								</div>
							</form>

						</CardContent>
					</Card>
					
					<Card>
						<CardContent>
							<h1 className="text-heading mb-4">Update Phone</h1>
							<TextField value={userData?.account_data.phone || ''} fullWidth label="Current Account Phone Number"/>
							<form action="/update-phone" method="post" className="mt-4">

								<TextField type="tel" name="phone" label="New Number" fullWidth required/>
								<div className="h-4"></div>

								<div className="flex justify-end">
									<Button type="submit" variant="contained">Update</Button>
								</div>

							</form>
						</CardContent>
					</Card>

					<Card>
						<CardContent>
							<h1 className="text-heading mb-4">Update Contact Name</h1>
							<TextField value={userData?.account_data.contact_name || ''} fullWidth label="Current Contact Name"/>
							<form action="/update-name" method="post" className="mt-4">

								<TextField name="name" label="New Contact Full Name" fullWidth required/>
								<div className="h-4"></div>

								<div className="flex justify-end">
									<Button type="submit" variant="contained">Update</Button>
								</div>
							</form>
						</CardContent>
					</Card>
					
				</div>

            </main>
        </div>
    );
}
